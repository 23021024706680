import React, { FunctionComponent } from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Show } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';

export const BreadcrumbHeader: FunctionComponent<BreadcrumbProps> = ({ breadcrumb }) => (
  <Show below="sm">
    <Breadcrumb paddingBlockEnd={2} sx={{ 'a[aria-current]': { color: 'brand.500' } }}>
      {breadcrumb.crumbs?.map(({ pathname, crumbLabel }) => {
        const label = crumbLabel.replaceAll('-', ' ').toLowerCase();

        return (
          <BreadcrumbItem key={pathname}>
            <BreadcrumbLink as={GatsbyLink} to={pathname} textTransform="capitalize">
              {label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  </Show>
);
