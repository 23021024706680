// eslint-disable-next-line import/no-extraneous-dependencies
import { checkboxAnatomy } from '@chakra-ui/anatomy';
import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Checkbox: ComponentStyleConfig = {
  parts: [...checkboxAnatomy.keys],
  baseStyle: () => ({
    control: {
      borderColor: 'qGray.100',
      borderRadius: 'base',
      boxShadow: 'xs',
      _focus: {
        boxShadow: 'outlineXs',
      },
    },
  }),
  defaultProps: {
    colorScheme: 'qGray',
  },
};
