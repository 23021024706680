const routes = {
  internal: {
    introduction: '/introduction',
    portal: '/portal',
    articles: '/articles',
    playground: '/graphql-playground',
  },
  external: {
    shop: 'https://iot-sim.tech/shop',
    privacy: 'https://www.qbeyond.de/datenschutz/',
    termsAndConditions: 'https://www.iot-sim.tech/agb/',
  },
};

export default routes;
