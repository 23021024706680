import React, { useContext, useState, useEffect, createContext, useRef } from 'react';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import type { FunctionComponent, MutableRefObject, PropsWithChildren } from 'react';
import { useConfig } from 'utils/hooks/useConfig';

interface AuthContextValues {
  session?: CognitoUserSession;
  isLoggedIn: boolean;
  bearerToken: MutableRefObject<string | undefined>;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

const defaultValues: AuthContextValues = {
  isLoggedIn: false,
  bearerToken: { current: undefined },
  login: () => Promise.reject(Error('NotReady')),
  logout: () => Promise.reject(Error('NotReady')),
};

export const AuthContext = createContext<AuthContextValues>(defaultValues);

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [config] = useConfig();
  const bearerToken = useRef<string>();

  const [ctxValues, setCtxValues] = useState<AuthContextValues>({
    ...defaultValues,
    bearerToken,
    login: async (username: string, password: string) => {
      await Auth.signIn(username, password);
      const session = await Auth.currentSession();

      setCtxValues((old) => ({ ...old, session }));
    },
    logout: async () => {
      await Auth.signOut();
      setCtxValues((old) => ({ ...old, session: undefined }));
    },
  });

  useEffect(() => {
    if (ctxValues.session == null || !ctxValues.session.isValid) {
      setCtxValues((old) => ({ ...old, isLoggedIn: false }));

      return () => 0;
    }

    const token = ctxValues.session.getAccessToken();
    const lifeTime = token.getExpiration() * 1000 - Date.now();

    bearerToken.current = token.getJwtToken();

    const cancel = setTimeout(async () => {
      const session = await Auth.currentSession();

      bearerToken.current = session.getAccessToken().getJwtToken();

      setCtxValues((old) => ({ ...old, session }));
    }, lifeTime);

    setCtxValues((old) => ({ ...old, isLoggedIn: true }));

    return () => {
      clearTimeout(cancel);
    };
  }, [ctxValues.session]);

  useEffect(() => {
    if (config) {
      Auth.configure({
        userPoolId: config.user_pool.id,
        region: config.user_pool.region,
        userPoolWebClientId: config.user_pool.web_client_id,
      });

      (async function checkUserSession() {
        try {
          const session = await Auth.currentSession();

          setCtxValues((old) => ({ ...old, session }));
        } catch (e) {
          /**/
        }
      })();
    }
  }, [config]);

  return <AuthContext.Provider value={ctxValues}>{children}</AuthContext.Provider>;
};
