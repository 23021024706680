/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { GatsbyBrowser, GatsbySSR } from 'gatsby';
import { AuthProvider } from 'utils/contexts/AuthContext';
import { theme } from '../utils/theme';
import Fonts from './Fonts';
import Layout from './Layout';
import { disableEmotionWarnings } from '../utils/console';
import '../utils/i18n/i18n';

type WrapPageElement = GatsbyBrowser['wrapPageElement'] | GatsbySSR['wrapPageElement'];

if (process.env.NODE_ENV !== 'production') {
  disableEmotionWarnings();
}

// Renders the DefaultLayout above all other pages without unmounting on page switch
export const wrapPageElement: WrapPageElement = ({ element, props }: any) => (
  <AuthProvider>
    <ChakraProvider theme={theme}>
      <Fonts />
      <Layout {...props}>{element}</Layout>
    </ChakraProvider>
  </AuthProvider>
);
