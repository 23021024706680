import type { ChakraTheme } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';
import { radii } from './borderRadius';
import { breakpoints } from './breakpoints';
import { colors } from './colors';
import * as components from './components';
import { config } from './config';
import { fonts } from './fonts';
import { shadows } from './shadows';

export const theme: Partial<ChakraTheme> = extendTheme({
  breakpoints,
  colors,
  components: { ...components },
  config,
  fonts,
  radii,
  shadows,
  styles: {
    global: {
      // it is easier to define the base font styles here than in all
      // components that need that style prop, e.g. Text, Heading, Link
      body: {
        color: 'qGray.500',
        background: 'qGray.50',
        fontSize: 'md',
        overflowY: 'scroll',
        scrollBehavior: 'smooth',
      },
      '#___gatsby': {
        fontFamily: 'var(--q-fonts-body)',
      },
      '.mdx-prose': {
        // fontFamily:
        //   '-apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
        lineHeight: 1.5,
        wordWrap: 'break-word',

        '*:first-child': {
          marginBlockStart: 0,
        },
        'h1, h2, h3, h4, h5, h6': {
          fontWeight: 'bold',
          marginBlockStart: '6',
          marginBlockEnd: '4',
        },
        'h1, h2': {
          paddingBlockEnd: '0.3em',
          borderBlockEnd: '1px solid #e6e6ea',
        },
        h1: {
          fontSize: '3xl',
        },
        h2: {
          fontSize: '2xl',
        },
        h3: {
          fontSize: 'xl',
        },
        h4: {
          fontSize: 'lg',
        },
        h5: {
          fontSize: 'md',
        },
        h6: {
          fontSize: 'md',
          color: 'qGray.400',
        },
        p: {
          marginBlockEnd: '4',
        },
        a: {
          color: 'blue.600',
          ':hover': {
            textDecoration: 'underline',
          },
        },
        blockquote: {
          fontSize: 'inherit',
          color: 'qGray.400',
          padding: '0 1rem',
          marginBlockEnd: '4',
          boxShadow: 'inset 4px 0 0 0 #dcdcde',
          'p:last-child': { marginBlockEnd: 0 },
        },
        'ol, ul': {
          padding: 0,
          margin: 0,
          marginBlockEnd: '4',

          li: {
            marginInlineStart: '6',
          },
        },
        '& :is(ol, ul) :is(ol, ul)': {
          marginBlockEnd: 0,
        },
        hr: {
          marginBlock: '4',
        },
        'sub, sup': {
          position: 'relative',
          fontSize: '75%',
          lineHeight: 0,
          verticalAlign: 'baseline',
        },
        sup: { insetBlockStart: '-0.5em' },
        table: {
          marginBlockEnd: '4',
        },
        'th, td': {
          border: '1px solid',
          borderColor: 'qGray.100',
          padding: '0.375rem 0.75rem',
          verticalAlign: 'top',
        },
        'thead > tr': {
          background: 'qGray.50',
        },
        'tbody > tr:nth-child(even)': {
          background: 'qGray.50',
        },
        '.gatsby-resp-image-wrapper': {
          display: 'block',
          marginBlockEnd: '4',
          border: '1px solid',
          borderColor: 'qGray.100',
          '&:hover': {
            borderColor: 'qGray.200',
          },
        },

        // only target inline code
        '& :not(.gatsby-highlight > pre) > code': {
          padding: '0.2em 0.4em',
          margin: 0,
          fontSize: '85%',
          whiteSpace: 'break-spaces',
          backgroundColor: 'qGray.50',
          borderRadius: 'base',
        },
        '.gatsby-highlight': {
          marginBlockEnd: '4',
          pre: {
            position: 'relative',
            borderColor: 'qGray.100',
            borderRadius: 'base',
            '.line-numbers-rows': {
              paddingBlock: '1.5em 1em',
              paddingInlineStart: '0.5em',
            },
            '&[class*="language-"]': {
              paddingBlockStart: '1.5em',
              '&:before': {
                borderRadius: '0px 0px 4px 4px',
                fontSize: '0.6rem',
                fontWeight: 400,
                letterSpacing: '0.075em',
                lineHeight: 1,
                padding: '0.25rem 0.5rem',
                position: 'absolute',
                left: '1rem',
                textAlign: 'right',
                textTransform: 'uppercase',
                textShadow: 'none',
                top: 0,
              },
              '&.line-numbers:before': {
                left: '6em',
              },
            },
          },
          '.gatsby-highlight-code-line': {
            display: 'block',
            position: 'relative',
            width: 'max-content',
            '&:before': {
              content: "''",
              display: 'block',
              inset: 0,
              insetInlineStart: '-15px',
              position: 'absolute',
              background: '#fff8c5',
              boxShadow: `inset 2px 0 0 #C53030`,
              opacity: 0.5,
            },
            '& > span': { position: 'relative' },
          },
        },
      },
      button: { outline: 'none' },
    },
  },
});
