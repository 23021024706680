import React, { FunctionComponent, useRef } from 'react';
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  IconButton,
  Link,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon, CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Link as GatsbyLink } from 'gatsby';
import { SideMenuLink } from '../SideMenu/SideMenu';

interface HamburgerMenuProps {
  links: SideMenuLink[];
}

const defaultLinkProps = {
  display: 'block',
  fontWeight: 'normal',
  paddingBlock: '1',
  marginBlock: '3',
};

interface HamburgerMenuListProps {
  links: SideMenuLink[];
  onDrawerClose: () => void;
}

const HamburgerMenuList: FunctionComponent<HamburgerMenuListProps> = ({ links, onDrawerClose }) => (
  <Box as="nav" sx={{ 'a[aria-current]': { color: 'brand.500' } }}>
    {links.map(({ text, to, divider }, index) => {
      if (divider) {
        return (
          <Box key={index}>
            <Divider />
            {text && (
              <Heading as="h6" size="md" marginBlock={4}>
                {text}
              </Heading>
            )}
          </Box>
        );
      }

      if (to) {
        const isExternal = to.startsWith('http');

        return (
          <Link
            key={to}
            {...(isExternal
              ? { href: to, isExternal: true }
              : { as: GatsbyLink, to, partiallyActive: true, activeStyle: { color: 'brand.500' } })}
            {...defaultLinkProps}
            onClick={onDrawerClose}
          >
            {text}
            {isExternal && <ChevronRightIcon boxSize={5} />}
          </Link>
        );
      }

      return null;
    })}
  </Box>
);

const HamburgerMenu: FunctionComponent<HamburgerMenuProps> = ({ links }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);

  return (
    <>
      <IconButton
        aria-label={t('header.hamburgerMenu')}
        variant="secondary"
        icon={<HamburgerIcon />}
        onClick={onOpen}
        ref={btnRef}
      />
      <Drawer isOpen={isOpen} onClose={onClose} size="xs" finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px" display="flex" justifyContent="flex-end">
            <Button variant="outline" rightIcon={<CloseIcon boxSize="3" />} onClick={onClose}>
              {t('header.closeBtn')}
            </Button>
          </DrawerHeader>
          <DrawerBody>
            <HamburgerMenuList links={links} onDrawerClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default HamburgerMenu;
