import { useEffect, useState } from 'react';

export interface Configuration {
  graphql_url: string;
  public_api_key: string;
  user_pool: {
    id: string;
    region: string;
    web_client_id: string;
  };
}

export const useConfig = (): [Configuration | undefined, string | undefined] => {
  const [config, setConfig] = useState<Configuration>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    let active = true;

    (async () => {
      if (config != null) {
        return;
      }

      try {
        const response = await fetch('/config.json');

        if (response.status !== 200) {
          setError('Could not load Configuration');

          return;
        }

        const cfg = await response.json();

        if (active) {
          setConfig(cfg);
          setError(undefined);
        }
      } catch (e) {
        if (active && e instanceof Error) {
          setError(e.message);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [config]);

  return [config, error];
};
