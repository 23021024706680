// eslint-disable-next-line import/no-extraneous-dependencies
import { inputAnatomy } from '@chakra-ui/anatomy';
import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Input: ComponentStyleConfig = {
  parts: [...inputAnatomy.keys],
  baseStyle: {
    field: {
      _placeholder: {
        color: 'qGray.400',
      },
    },
  },
  sizes: {
    sm: {
      field: {
        fontSize: 'md',
        borderRadius: 1,
      },
    },
  },
  variants: {
    userData: {
      field: {
        border: '1px solid',
        borderColor: 'qGray.300',
        borderRadius: 1,
        padding: 4,
        fontWeight: 'bold',
        fontSize: 'md',

        _placeholder: {
          fontWeight: 'bold',
          fontSize: 'md',
        },
        _disabled: {
          borderColor: 'qGray.100',
        },
      },
    },
    searchbar: {
      field: {
        border: '1px solid',
        borderColor: 'qGray.500',
      },
    },
    shop: {
      field: {
        background: 'qGray.50',
        borderRadius: 'Q',
        p: 4,
        h: '3.3125rem',

        _disabled: {
          color: 'qGray.200',
        },
      },
    },
  },
};
