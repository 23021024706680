export default {
  translation: {
    header: {
      logo: 'Developer Hub',
      closeBtn: 'Close',
      hamburgerMenu: 'hamburger menu',
    },
    sideMenu: {
      introduction: 'Introduction',
      portal: 'Portal',
      articles: 'Articles',
      graphQl: 'GraphQL Playground',
    },
    footer: {
      heading: 'q.beyond AG',
      nav: {
        aboutUs: 'About Us',
        imprint: 'Imprint',
        privacyPolicy: 'Privacy Policy',
        jobs: 'Jobs',
        faq: 'FAQ',
        termsOfUse: 'Terms of Use',
        termsAndConditions: 'Terms and Conditions',
      },
      socialLinks: {
        twitter: 'Twitter',
        youtube: 'YouTube',
        facebook: 'Facebook',
        xing: 'Xing',
        linkedIn: 'LinkedIn',
      },
    },
    graphQlPlayground: {
      loginWarning: 'You need to login to use queries and mutations!',
      signIn: {
        heading: 'Sign in with your IoT SIM Portal credentials',
        username: 'Username',
        password: 'Password',
        login: 'Login',
        logout: 'Logout',
        show: 'Show',
        hide: 'Hide',
      },
    },
    pages: {
      introduction: {
        heading:
          'Welcome to the IoT SIM Developer Hub, your one-stop destination for unlocking the full power of IoT SIMs.',
        body: `Our comprehensive documentation, portal, and API empower developers to seamlessly integrate
				IoT SIM functionality into their applications. Whether you're building smart cities,
				industrial automation systems, or healthcare solutions, our platform provides the tools and
				resources you need to harness the true potential of IoT connectivity. With our intuitive
				portal and powerful API, you can easily manage SIM cards, monitor usage, and leverage advanced
				features to drive innovation.`,
      },
    },
  },
};
