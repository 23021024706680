import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Link, Flex, IconButton, Box, Heading } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import { BsFacebook, BsLinkedin, BsTwitter, BsYoutube } from 'react-icons/bs';
import { FaXing } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { maxContentWidth } from '../utils/styles';

const footerNav = [
  { i18nKey: 'footer.nav.aboutUs', href: 'https://www.iot-sim.tech/ueber-uns' },
  { i18nKey: 'footer.nav.imprint', href: 'https://www.qbeyond.de/impressum' },
  { i18nKey: 'footer.nav.privacyPolicy', href: 'https://www.qbeyond.de/datenschutz' },
  {
    i18nKey: 'footer.nav.termsOfUse',
    href: 'https://www.qbeyond.de/nutzungsbedingungen',
  },
  { i18nKey: 'footer.nav.termsAndConditions', href: 'https://www.iot-sim.tech/agb' },
  { i18nKey: 'footer.nav.faq', href: 'https://www.iot-sim.tech/iot-sim-facts/#faq' },
  { i18nKey: 'footer.nav.jobs', href: 'https://karriere.qbeyond.de' },
];

const socialLinks = [
  { i18nKey: 'footer.socialLinks.twitter', href: 'https://twitter.com/qbeyondag', icon: BsTwitter },
  {
    i18nKey: 'footer.socialLinks.facebook',
    href: 'https://www.facebook.com/qbeyondAG',
    icon: BsFacebook,
  },
  {
    i18nKey: 'footer.socialLinks.youtube',
    href: 'https://www.youtube.com/c/qbeyondag',
    icon: BsYoutube,
  },
  {
    i18nKey: 'footer.socialLinks.xing',
    href: 'https://www.xing.com/pages/qbeyondag',
    icon: FaXing,
  },
  {
    i18nKey: 'footer.socialLinks.linkedIn',
    href: 'https://www.linkedin.com/company/qbeyond-ag',
    icon: BsLinkedin,
  },
];

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box
      w="full"
      padding="4"
      paddingBlockStart="8"
      margin="auto"
      maxWidth={maxContentWidth}
      color="white"
    >
      <Heading
        as="h6"
        size="md"
        fontFamily="Brix Sans Black,Brix Sans,sans-serif"
        marginBlockEnd="5"
      >
        {t('footer.heading')}
      </Heading>
      <Flex justify="space-between" flexWrap="wrap" gap={8}>
        <Box flexBasis={650} paddingInlineEnd={4}>
          <Box
            as="nav"
            columnGap="6rem"
            sx={{
              columnCount: 'auto',
              columnFill: 'balanced',
              columnWidth: '11rem',
            }}
          >
            {footerNav.map(({ i18nKey, href }) => {
              const label = t(i18nKey);

              return (
                <Link
                  key={href}
                  variant="hoverBrand"
                  {...(href.startsWith('http')
                    ? { isExternal: true, href }
                    : { as: GatsbyLink, to: href })}
                  display="block"
                  lineHeight={1}
                  paddingBlock={2}
                  textTransform="capitalize"
                >
                  <ArrowForwardIcon marginBlockStart={-1} marginInlineEnd={1} />
                  {label}
                </Link>
              );
            })}
          </Box>
        </Box>
        <Flex flexWrap={['wrap', 'nowrap']} gap={4}>
          {socialLinks.map(({ i18nKey, href, icon: Icon }) => {
            const label = t(i18nKey);

            return (
              <Link key={href} href={href} isExternal>
                <IconButton
                  size="lg"
                  variant="outline"
                  aria-label={label}
                  title={label}
                  background="white"
                  icon={<Icon size={24} color="black" />}
                />
              </Link>
            );
          })}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
