// eslint-disable-next-line import/no-extraneous-dependencies
import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Select: ComponentStyleConfig = {
  baseStyle: {},
  sizes: {
    sm: {
      field: {
        fontSize: 'md',
        borderRadius: 'base',
        height: '34px',
      },
    },
  },
  variants: {
    userData: {
      field: {
        border: '1px solid',
        borderColor: 'qGray.100',
        borderRadius: 'base',
        fontWeight: 'bold',
        fontSize: 'md',
      },
    },
  },
};
