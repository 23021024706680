// eslint-disable-next-line import/no-extraneous-dependencies
import { switchAnatomy } from '@chakra-ui/anatomy';
import { cssVar, type ComponentStyleConfig } from '@chakra-ui/react';

export const Switch: ComponentStyleConfig = {
  parts: [...switchAnatomy.keys],
  baseStyle: {
    track: {
      bg: 'qGray.100',
      _checked: {
        bg: 'qGreen.500',
      },
      _focus: {
        boxShadow: 'outlineSm',
      },
    },
  },
  sizes: {
    xl: {
      container: {
        // values from sim cards page screendesigns (53x32px) -4px padding from the track
        [cssVar('switch-track-width').variable]: '3.0625rem', // 49px
        [cssVar('switch-track-height').variable]: '1.75rem', // 28px
      },
    },
  },
  defaultProps: {
    size: 'xl',
  },
};
