// eslint-disable-next-line import/no-extraneous-dependencies
import { radioAnatomy } from '@chakra-ui/anatomy';
import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Radio: ComponentStyleConfig = {
  parts: [...radioAnatomy.keys],
  baseStyle: {
    control: {
      _checked: {
        _before: {
          h: 0,
        },
      },
    },
    label: {
      _checked: {
        fontWeight: 'bold',
      },
    },
  },
  sizes: {
    sm: {
      label: {
        fontSize: 'md',
      },
    },
  },
  defaultProps: {
    size: 'sm',
    colorScheme: 'qGray',
  },
};
