import { Box, Grid, GridItem, Hide, SimpleGrid } from '@chakra-ui/react';
import type { PageProps } from 'gatsby';
import type { FunctionComponent, PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from 'components/Footer';
import Header from 'components/Header';
import type { SideMenuLink } from './SideMenu/SideMenu';
import SideMenu from './SideMenu/SideMenu';
import sideMenuLinks from './SideMenu/sideMenuLinks';
import { gridSpacing, headerPlusSpacingSize, maxContentWidth } from '../utils/styles';
import { BreadcrumbHeader } from './BreadcrumbHeader';
import routes from '../utils/routes';

interface TocProps {
  url: string;
  title: string;
  items?: TocProps[];
}

const getTOCMenu = (toc: { items: TocProps[] }, slug: string): SideMenuLink[] =>
  toc.items.map((item) => ({
    text: item.title,
    to: `${slug}${item.url}`,
    ...(item.items && { items: getTOCMenu({ items: item.items }, slug) }), // uncomment to make this recursive
  }));

const addDocsNavigation = (
  menuLinks: SideMenuLink[],
  nodes: Queries.ALL_MDX_ARTICLESQuery['allMdx']['nodes'],
) => {
  const index = menuLinks.findIndex(({ i18nKey }) => i18nKey === 'sideMenu.articles');

  if (index > -1) {
    const items = [...nodes]
      .sort((a, b) => {
        // sort ascending
        if (b.frontmatter?.title && a.frontmatter?.title) {
          const title1 = b.frontmatter.title;
          const title2 = a.frontmatter.title;

          if (title2 < title1) {
            return -1;
          }

          if (title1 < title2) {
            return 1;
          }
        }

        return 0;
      })
      .reduce<SideMenuLink[]>((acc, { frontmatter, tableOfContents }) => {
        if (frontmatter && frontmatter.slug && frontmatter.title) {
          const to = frontmatter.slug.startsWith(routes.internal.articles)
            ? frontmatter.slug
            : `${routes.internal.articles}/${frontmatter.slug}`;

          const item = {
            to,
            text: frontmatter.slugName || frontmatter.title,
            ...(tableOfContents?.items
              ? { items: getTOCMenu(tableOfContents as { items: TocProps[] }, to) }
              : {}),
          };

          return [...acc, item];
        }

        return acc;
      }, []);

    const updatedMenuLinks = [...menuLinks];

    updatedMenuLinks[index] = {
      ...updatedMenuLinks[index],
      items,
    };

    return updatedMenuLinks;
  }

  return menuLinks;
};

const Layout: FunctionComponent<
  PropsWithChildren<PageProps<Partial<Queries.ALL_MDX_ARTICLESQuery>, BreadcrumbProps>>
> = ({ children, data, pageContext, location }) => {
  const { t } = useTranslation();

  const links = useMemo(() => {
    const menuLinks = sideMenuLinks.map((item) => ({ ...item, text: t(item.i18nKey) }));

    return data?.allMdx?.nodes ? addDocsNavigation(menuLinks, data.allMdx.nodes) : menuLinks;
  }, [data, t]);

  return (
    <Grid
      templateAreas={'"header" "main" "footer"'}
      gridTemplateRows="min-content 1fr min-content"
      gap="8"
      minHeight="100vh"
      background="qGray.50"
    >
      <GridItem
        area="header"
        bg="white"
        as="header"
        position="sticky"
        top={0}
        zIndex="sticky"
        marginBlockEnd={`calc(${gridSpacing} * -1)`}
        borderBlockEndWidth={gridSpacing}
        borderBlockEndColor="qGray.50"
      >
        <Header links={links} />
      </GridItem>
      <GridItem area="main" paddingInline="4" marginInline="auto" maxWidth="80rem" width="full">
        {pageContext.breadcrumb && <BreadcrumbHeader breadcrumb={pageContext.breadcrumb} />}
        <SimpleGrid
          columns={2}
          gap="4"
          maxWidth={maxContentWidth}
          templateColumns={['1fr', null, null, '12rem 1fr']}
        >
          <Hide below="lg">
            <GridItem
              as="aside"
              position="sticky"
              top={headerPlusSpacingSize}
              maxHeight={`calc(100vh - ${headerPlusSpacingSize})`} // header-size = 104px, spacing = 32px
              overflowY="auto"
            >
              <Box as="nav" background="white">
                <SideMenu links={links} location={location} />
              </Box>
            </GridItem>
          </Hide>
          <GridItem as="main" maxWidth="full" overflow="hidden">
            {children}
          </GridItem>
        </SimpleGrid>
      </GridItem>
      <GridItem area="footer" backgroundColor="black" as="footer">
        <Footer />
      </GridItem>
    </Grid>
  );
};

export default Layout;
