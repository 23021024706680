import type { RecursiveObject } from '@chakra-ui/react';
import { colors } from './colors';

export const shadows: RecursiveObject<string> = {
  sideMenuInset: `inset 2px 0 0 0 ${colors.brand[500]}`,
  sideMenuInsetRed: `inset 2px 0 0 0 ${colors.red[600]}`,
  overlay: `0 5px 12px ${colors.qGray[200]}`,
  outline: `0px 0px 0px 3px ${colors.brand[500]}`, // this is the default boxShadow with the brand color
  outlineSm: `0px 0px 0px 2px ${colors.brand[500]}`,
  outlineXs: `0px 0px 0px 1.75px ${colors.brand[500]}`,
};
