interface HeaderLinks {
  link: string;
  displayName: string;
}

export const menuEntries: HeaderLinks[] = [
  {
    link: 'https://www.iot-sim.tech/',
    displayName: 'LandingPage',
  },
  {
    link: 'https://www.iot-sim.tech/shop/',
    displayName: 'Shop',
  },
  {
    link: 'https://portal.iot-sim.tech',
    displayName: 'Portal',
  },
];
