import { Center, Link, Flex, Show, Spacer, Text, Icon } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { ReactComponent as QbeyondLogo } from 'images/qbeyondLogo.svg';
import { menuEntries } from './menuEntries';
import { SideMenuLink } from '../SideMenu/SideMenu';
import HamburgerMenu from './HamburgerMenu';
import { maxContentWidth, maxHeaderHeight } from '../../utils/styles';

interface HeaderProps {
  links: SideMenuLink[];
}

const Header: FunctionComponent<HeaderProps> = ({ links }) => {
  const { t } = useTranslation();

  const combinedLinks = useMemo<SideMenuLink[]>(
    () => [
      ...links.map(({ items, ...rest }) => rest), // remove submenu
      { divider: true, text: 'IOT Sim external pages' },
      ...menuEntries.map(({ link, displayName }) => ({ to: link, text: displayName })), // map to SideMenuLink format
    ],
    [links],
  );

  return (
    <Center w="full" bgColor="white">
      <Flex
        p="4"
        w="full"
        maxWidth={maxContentWidth}
        maxHeight={maxHeaderHeight}
        align="center"
        justify="space-between"
        gap="8"
      >
        <Link
          as={GatsbyLink}
          to="/"
          display="flex"
          justifyContent="flex-start"
          gap={[2, null, null, 0]}
          flexDirection={['row', null, null, 'column']}
        >
          <Icon
            as={QbeyondLogo}
            aria-label="qBeyond"
            width={['8rem', null, null, '11rem']}
            height={['auto', null, null, '36px']}
          />
          <Text fontFamily="var(--q-fonts-body)" fontSize={['xl', '2xl']}>
            {t('header.logo')}
          </Text>
        </Link>

        <Show above="lg">
          <Flex as="nav" gap={8} flexWrap="wrap">
            {menuEntries.map((entry) => {
              const isExternal = entry.link.startsWith('http');

              return (
                <Link
                  key={entry.displayName}
                  {...(isExternal
                    ? { isExternal: true, href: entry.link }
                    : { as: GatsbyLink, to: entry.link })}
                  fontSize="1.25rem"
                  fontWeight="bold"
                >
                  {entry.displayName}
                  {isExternal && <ChevronRightIcon boxSize={5} />}
                </Link>
              );
            })}
          </Flex>
          <Spacer />
        </Show>
        <Show below="lg">
          <HamburgerMenu links={combinedLinks} />
        </Show>
      </Flex>
    </Center>
  );
};

export default Header;
