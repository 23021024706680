import routes from 'utils/routes';
import { SideMenuLinkRaw } from './SideMenu';

const sideMenuLinks: SideMenuLinkRaw[] = [
  {
    to: routes.internal.introduction,
    i18nKey: 'sideMenu.introduction',
  },
  {
    to: routes.internal.portal,
    i18nKey: 'sideMenu.portal',
  },
  {
    to: routes.internal.articles,
    i18nKey: 'sideMenu.articles',
  },
  {
    to: routes.internal.playground,
    i18nKey: 'sideMenu.graphQl',
  },
];

export default sideMenuLinks;
